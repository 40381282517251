:root {
  --avatar-size: 2.5rem;
  --light-color: #FAFAFB;
  --miells-background: #57748c;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
.custom-select,
.react-select,
.custom-select:focus,
textarea {
  font-size: 16px;
}

.react-select__control{
  height: 41px;
}

.dropdown-toggle:after{
  display: none;
}

.page-title { 
  margin: 10px 0;
}

.logo-mobile{
  width: 100%;
}
.navbar-custom{
  padding: 0;
}
.navbar-custom .button-menu-mobile{
  margin: 0;
  line-height: normal;
}
.navbar-custom .menu-left {
  overflow: hidden;
  min-width: 60px;
}

/* Progress bar */
.progress-bar {
  position: relative !important;
  bottom: auto !important;
  right: auto !important;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  margin: 1rem 0;
  padding-top: 0;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(34, 34, 34, 0.33);
  background-color: var(--miells-background);
}
.progress-bar a{
  color: #fff;
}
.progress-bar p:last-child{
  margin-bottom: 0;
}
.bg-miells{
  border-color: var(--miells-background) !important;
  background-color: var(--miells-background) !important;
}
.progress-bar .value > div {
  text-align: center;
}
.progress-bar .scale .graduation {
  text-align: center;
}
.progress-bar .bar > div {
  display: inline-block;
  height: 2px;
}
.progress-bar .legend {
  text-align: center;
}
.progress-bar .legend > div {
  display: inline-block;
  margin: 0 5px;
  text-align: center;
}
.progress-bar .legend > div .dot {
  font-size: 25px;
  vertical-align: middle;
}
.progress-bar .legend > div .label {
  margin-left: 2px;
  vertical-align: middle;
  color:rgba(255, 255, 255, 1);
  font-weight: 300;
  font-size: 0.9em;
}

.fc-bootstrap a a{
  color: #fff;
}

.btn-back{
  color: #6c757d;
  font-size: 1.1rem;
  text-decoration: none;
}

.event .btn-secondary{
  width: 100%;
  margin-top: 3em;
  color: #fff;
  background-color: #ff5c75;
  border-color: #ff5c75;
}
.event .btn-secondary:hover {
  color: #fff;
  background-color: #ff3655;
  border-color: #ff294a;
}

.componentStyles {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 0 30px;
}

.linkStyles {
  text-decoration: none;
  display: block;
  color: #e42d2d;
  font-size: 18px;
  text-align: center;
  padding: 6px;
  background-color: #fff;
}

.footer{
  position: relative;
}


@media only screen and (max-width: 767px) {
  .progress-bar {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 0 1rem 1rem;
  }

  /* Calendrier */
  .fc-toolbar{
    flex-direction: column;
  }
  .fc-day-grid-event,
  .fc-time-grid .fc-event, 
  .fc-time-grid .fc-bgevent{
    width: 150px;
    left: -100% !important;
  }
}